// import { useConnectWallet } from '@web3-onboard/react';
import cx from 'classnames';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
// import ReferralBanner from 'components/ReferralBanner';
// import ReferralFooter from 'components/ReferralFooter';
import useOnScreen from 'hooks/useOnScreen';
import useRecursiveTimeout from 'hooks/useRecursiveTimeout';
import React, {
    useCallback, useRef, useState
} from 'react';

function SellLiquidityPage() {
    // const [{ wallet }] = useConnectWallet();
    // const userAddress = wallet?.accounts[0].address;

    // video autoplay
    const [playing, setPlaying] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);
    const playVideo = useCallback(() => {
        if (isVisible && !playing) {
            setPlaying(1);
        }
    }, [
        setPlaying,
        isVisible,
        playing
    ]);
    useRecursiveTimeout(playVideo, 100);

    return (
        <DashboardLayout
            hasConnectButton={false}
            banner={
                <hr
                    style={{
                        margin: 0,
                        borderTop: '1px solid #1882c4',
                    }}
                />
            }
            hasSidebar={false}
            hasExandedLogo
            isDarkThemeOverride={false}
            hasDarkmodeBtn={false}
        >
            <link rel='stylesheet' href='/css/landing.css' />
            <div className={cx('landing_container', 'sellLiquidityPage')}>
                <h1>Get the maximum amount of BankX tokens!</h1>
                <h4
                    className='blueText'
                    style={{
                        lineHeight: 1.8,
                        fontWeight: 'bold',
                        fontSize: '1.6rem',
                    }}
                >
            Selling your crypto directly into the BankX Liquidity Pools gets
            the maximum amount of BankX tokens AND XSD stablecoins.
                </h4>
                <div className='referralFooter' style={{ marginTop: 0 }}>
                    <p>Even More Than Swapping.</p>
                    <p>Watch the quick video below to find out how.</p>
                    <p>
              BankX offers rewards with more BankX tokens and XSD stablecoins
              when the system needs more liquidity. You help BankX and get more
              BankX and XSD for doing so.
                    </p>
                    <p>The earlier you participate, the greater the rewards.</p>
                    <p style={{ marginBottom: 8 }}>
              The earliest even receive XSD stablecoins in addition to BankX
              tokens.
                    </p>
                </div>
                <div ref={ref}>
                    <iframe
                        width='560'
                        height='315'
                        src={`https://www.youtube.com/embed/xS4QFduUlfI?si=bOvrObiiaXiS8F3Q&rel=0&amp;autoplay=${playing}&mute=1`}
                        title='YouTube video player'
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                        className='youtubeVideo'
                    />
                </div>
                <button
                    style={{
                        marginTop: 4,
                        marginBottom: 8,
                    }}
                    className='header-button'
                    onClick={() => (window.location.href = '/#/liquidity-pool')}
                >
            SELL LIQUIDITY
                </button>
            </div>
        </DashboardLayout>
    );
}

export default SellLiquidityPage;
