export enum Contract {
    BankXToken           = 'BankXToken',
    XSDToken             = 'XSDToken',
    BankXPool            = 'BankXPool',
    XSDPool              = 'XSDPool',
    CollateralPool       = 'CollateralPool',
    PIDController        = 'PIDController',
    RewardManager        = 'RewardManager',
    RouterAddress        = 'RouterAddress',
    Arbitrage            = 'Arbitrage',
    CertificateOfDeposit = 'CertificateOfDeposit',
    BankXNFT             = 'BankXNFT',
    NFTBonus             = 'NFTBonus',
    XAGUSDPriceConsumer  = 'XAGUSDPriceConsumer',
    Proxy                = 'Proxy',
    NFTCollector         = 'NFTCollector',
}

// for convenience: used to avoid having to rename contracts
export enum ContractName {
    BankXToken           = 'bankxTokenContract',
    XSDToken             = 'xsdTokenContract',
    BankXPool            = 'bankxPoolContract',
    XSDPool              = 'xsdPoolContract',
    CollateralPool       = 'collateralPoolContract',
    PIDController        = 'pidContract',
    RewardManager        = 'rewardsContract',
    RouterAddress        = 'routerContract',
    Arbitrage            = 'arbitrageContract',
    CertificateOfDeposit = 'cdContract',
    BankXNFT             = 'bankxNftContract',
    NFTBonus             = 'nftBonusContract',
    XAGUSDPriceConsumer  = 'xagUSDContract',
    Proxy                = 'proxyContract',
    NFTCollector         = 'bankxNftCollector',
}

export type AddressMap = Partial<Record<Contract, string>>;
