import cx from 'classnames';
import { HexAddress } from 'components/HexAddress';
import { LeaderboardEntry, useNftLeaderboard } from 'context/NftLeaderboardProvider';
import { useWallet } from 'hooks/useWallet';
import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { SingleValue } from 'react-select';

import Styles from './styles.module.scss';

const chainCodes = [
    {
        label: 'Ethereum',
        value: '0x1'
    },
    {
        label: 'BSC',
        value: '0x38'
    },
    {
        label: 'Polygon',
        value: '0x89'
    },
    {
        label: 'Arbitrum',
        value: '0xa4b1'
    },
    {
        label: 'Avalanche',
        value: '0xa86a'
    },
    {
        label: 'Fantom',
        value: '0xfa'
    },
    {
        label: 'Optimism',
        value: '0xa'
    },
    {
        label: 'Base',
        value: '0x2105'
    }
];

interface LeaderboardListProps {
    entries: LeaderboardEntry[];
    limit?: number;
}

function EmptyRow(): React.ReactElement {
    return (
        <tr>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
    );
}

function LeaderboardList({ entries, limit }: LeaderboardListProps): React.ReactElement {
    const l = limit ?? entries.length;
    const showEmptyRow = entries.length === 0;

    return (
        <table className={Styles.list}>
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Referrer Address</th>
                    <th>NFTs Referred</th>
                </tr>
            </thead>
            <tbody></tbody>
            {entries.slice(0, l).map((entry, index): React.ReactElement => {
                const c = cx({ [Styles.user]: entry.isUser });
                const rank = index + 1;

                return (
                    <tr key={index} className={c}>
                        <td>{rank}</td>
                        <td><HexAddress address={entry.address} /></td>
                        <td>{entry.amount}</td>
                    </tr>
                );
            })}
            {showEmptyRow && <EmptyRow />}
        </table>
    );
}

interface ChainOption {
    label: string;
    value: string;
}

export function NftLeaderboard(): React.ReactElement {
    const { chain: walletChain, isConnected } = useWallet();
    const [manualChain, setManualChain] = useState<ChainOption>(() => {
        const chain = walletChain ?? '0x1';
        const option = chainCodes.find(c => c.value === chain) ?? chainCodes[0];
        return option;
    });
    const {
        currentWeekTop5, overall, setSelectedChain
    } = useNftLeaderboard();

    function handleChainChange(option: SingleValue<ChainOption>) {
        if (option) {
            setManualChain(option);
            setSelectedChain(option.value);
        }
    }

    return (
        <div className={cx(Styles.leaderboard, 'p-4')}>
            <div className={Styles.title}>
                NFT Referrals Leaderboard
            </div>
            <div>
                <p>Each week, the NFT sales leader on each chain will get 1 free NFT on that chain.</p>
                <p>(Minimum of 5 NFT’s need to sell from 3 different wallets that week to qualify.)</p>
                <p>When 5,000 NFT’s sell on each chain, the OVERALL referral leaders will get:</p>
                <ul>
                    <li>First Place: 3 NFT’s</li>
                    <li>Second Place: 2 NFT’s</li>
                    <li>Third Place: 1 NFT</li>
                </ul>
            </div>
            {!isConnected && (
                <div className='form-group' style={{
                    maxWidth: 150,
                    margin: '20px auto'
                }}>
                    <ReactSelect
                        menuPlacement='top'
                        options={chainCodes}
                        hideSelectedOptions={false}
                        onChange={handleChainChange}
                        value={manualChain}
                        placeholder='Chain'
                        className='selectDropdown'
                        styles={{
                            menuList: (baseStyles) => ({
                                ...baseStyles,
                                background: 'white',
                                zIndex: 9999,
                            }),
                            indicatorsContainer: (baseStyles) => ({
                                ...baseStyles,
                                background: '#38436414',
                                color: '#01303c',
                                width: 60,
                                paddingLeft: 12,
                                borderTopRightRadius: 9,
                                borderBottomRightRadius: 9,
                            }),
                            dropdownIndicator: (baseStyles) => ({
                                ...baseStyles,
                                color: '#01303c',
                            }),
                            indicatorSeparator: (baseStyles) => ({
                                ...baseStyles,
                                display: 'none',
                            }),
                            option: (baseStyles, { isFocused }) => ({
                                ...baseStyles,
                                background: isFocused ? 'rgb(24, 130, 197)' : 'white',
                                color: 'rgb(33, 37, 41)',
                            }),
                            control: (baseStyles, { isFocused }) => ({
                                ...baseStyles,
                                ...(isFocused && {
                                    boxShadow: '0 0 0 0.2rem rgb(0 123 255 / 25%)',
                                    border: 'none',
                                }),
                                borderRadius: 9,
                            }),
                            placeholder: (baseStyles) => ({
                                ...baseStyles,
                                paddingLeft: 20,
                                color: '#283280',
                                opacity: 0.4,
                            }),
                        }}
                    />
                </div>
            )}
            <section className={Styles.leaderboardSection}>
                <div className={cx(Styles.listTitle, 'pb-2')}>Current Week</div>
                <LeaderboardList entries={currentWeekTop5} limit={5}/>
            </section>
            <section className={Styles.leaderboardSection}>
                <div className={cx(Styles.listTitle, 'pb-2')}>Overall</div>
                <LeaderboardList entries={overall} limit={5}/>
            </section>
        </div>
    );
}
