import { toHex } from 'helpers/number';
import { mapValues } from 'lodash';

import { isProd } from './env';

// https://chainlist.org/
export enum ChainIdsInt {
    BinanceSmartChain = 56,
    BinanceTestnet = 97,
    EthereumMainnet = 1,
    Kovan = 42,
    Rinkeby = 4,
    Goerli = 5,
    Sepolia = 11155111,
    ArbitrumOne = 42161,
    PolygonMainnet = 137,
    OptimismMainnet = 10,
    Avalanche = 43114,
    Fantom = 250,
    Base = 8453,
}

export const ChainIdsHex = mapValues(ChainIdsInt, toHex);

interface ChainMeta {
    id: string;
    rpcUrl: string;
    token: string;
    label: string;
    wethAddress?: string;
}

export const CustomTokenLabels: Record<number, string> = {
    [ChainIdsInt.ArbitrumOne]: 'aETH',
    [ChainIdsInt.OptimismMainnet]: 'oETH',
}

export const Chains: Record<string, ChainMeta> = {
    [ChainIdsInt.Rinkeby]: {
        id: ChainIdsHex.Rinkeby,
        // BSC testnet.... shouldn't this be used in network 97 instead???
        // reference: https://github.com/Lance-Parker/Claim-App/blob/dfd34e37256ef7daf1e6c121cae7ed7de785c415/src/components/layouts/header/ConnectBtn.js#L18
        // rpcUrl: 'https://speedy-nodes-nyc.moralis.io/de5ffdecc0da895478c3c5e5/rinkeby/testnet',
        rpcUrl: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        // rpcUrl: 'https://rpc.ankr.com/eth_rinkeby',
        token: 'ETH',
        label: 'Rinkeby Testnet',
        // wethAddress: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    },
    [ChainIdsInt.Goerli]: {
        id: ChainIdsHex.Goerli,
        // BSC testnet.... shouldn't this be used in network 97 instead???
        // reference: https://github.com/Lance-Parker/Claim-App/blob/dfd34e37256ef7daf1e6c121cae7ed7de785c415/src/components/layouts/header/ConnectBtn.js#L18
        // rpcUrl: 'https://speedy-nodes-nyc.moralis.io/de5ffdecc0da895478c3c5e5/rinkeby/testnet',
        rpcUrl: 'https://goerli.infura.io/v3/0585168c69d04feeb74f94a49d9f2356',
        // rpcUrl: 'https://rpc.ankr.com/eth_rinkeby',
        token: 'ETH',
        label: 'Goerli Testnet',
        // wethAddress: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    },
    [ChainIdsInt.Sepolia]: {
        id: ChainIdsHex.Sepolia,
        // BSC testnet.... shouldn't this be used in network 97 instead???
        // reference: https://github.com/Lance-Parker/Claim-App/blob/dfd34e37256ef7daf1e6c121cae7ed7de785c415/src/components/layouts/header/ConnectBtn.js#L18
        // rpcUrl: 'https://speedy-nodes-nyc.moralis.io/de5ffdecc0da895478c3c5e5/rinkeby/testnet',
        rpcUrl: 'https://sepolia.infura.io/v3/0585168c69d04feeb74f94a49d9f2356',
        // rpcUrl: 'https://endpoints.omniatech.io/v1/eth/sepolia/public',
        token: 'ETH',
        label: 'Sepolia Testnet',
        // wethAddress: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    },
    [ChainIdsInt.BinanceSmartChain]: {
        id: ChainIdsHex.BinanceSmartChain,
        token: 'BNB',
        label: 'BNB Smart Chain Mainnet',
        // rpcUrl: 'https://endpoints.omniatech.io/v1/bsc/mainnet/public',
        // rpcUrl: 'https://bsc-dataseed1.defibit.io',
        // rpcUrl: 'https://bsc-dataseed.binance.org',
        // rpcUrl: 'https://bsc-dataseed.bnbchain.org',
        // rpcUrl: 'https://bsc-dataseed-public.bnbchain.org',
        rpcUrl: 'https://bsc-dataseed1.bnbchain.org',
        // rpcUrl: 'https://1rpc.io/bnb',
        // rpcUrl: 'https://bsc-dataseed1.binance.org/',
        // rpcUrl: 'https://bsc-dataseed2.binance.org/',
        // rpcUrl: 'https://bsc-dataseed3.binance.org',
        // rpcUrl: 'https://bsc-dataseed4.binance.org',
        // rpcUrl: 'https://endpoints.omniatech.io/v1/bsc/mainnet/public',
        // rpcUrl: 'https://bsc.publicnode.com',
    },
    [ChainIdsInt.EthereumMainnet]: {
        id: ChainIdsHex.EthereumMainnet,
        token: 'ETH',
        label: 'Ethereum Mainnet',
        rpcUrl: 'https://mainnet.infura.io/v3/050904dc37ca4946ab6c6a3901990453',
    },
    [ChainIdsInt.Kovan]: {
        id: ChainIdsHex.Kovan,
        token: 'KETH',
        label: 'Kovan Test Network',
        rpcUrl: 'https://kovan.infura.io/v3/050904dc37ca4946ab6c6a3901990453',
    },
    [ChainIdsInt.BinanceTestnet]: {
        id: ChainIdsHex.BinanceTestnet,
        token: 'BNB',
        label: 'BSC Testnet',
        rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        // rpcUrl: 'https://endpoints.omniatech.io/v1/bsc/testnet/public',
    },
    [ChainIdsInt.ArbitrumOne]: {
        id: ChainIdsHex.ArbitrumOne,
        token: 'ETH',
        label: 'Arbitrum One',
        // rpcUrl: 'https://arbitrum-one.publicnode.com',
        // rpcUrl: 'https://rpc.tornadoeth.cash/arbitrum',
        // rpcUrl: 'https://arbitrum-one.publicnode.com',
        rpcUrl: 'https://arb1.arbitrum.io/rpc',
    },
    [ChainIdsInt.PolygonMainnet]: {
        id: ChainIdsHex.PolygonMainnet,
        token: 'POL',
        label: 'Polygon Mainnet',
        rpcUrl: 'https://polygon-rpc.com', // official
        // rpcUrl: 'https://1rpc.io/matic',
        // rpcUrl: 'https://polygon.drpc.org',
    },
    [ChainIdsInt.OptimismMainnet]: {
        id: ChainIdsHex.OptimismMainnet,
        token: 'ETH',
        label: 'OP Mainnet',
        rpcUrl: 'https://mainnet.optimism.io',
    },
    [ChainIdsInt.Avalanche]: {
        id: ChainIdsHex.Avalanche,
        token: 'AVAX',
        label: 'Avalanche C-Chain',
        rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    },
    [ChainIdsInt.Fantom]: {
        id: ChainIdsHex.Fantom,
        token: 'FTM',
        label: 'Fantom Opera',
        // rpcUrl: 'https://rpc.ankr.com/fantom',
        rpcUrl: 'https://rpcapi.fantom.network',
        // rpcUrl: 'https://rpc.ftm.tools',
    },
    [ChainIdsInt.Base]: {
        id: ChainIdsHex.Base,
        token: 'ETH',
        label: 'Base',
        // https://docs.base.org/docs/network-information/
        rpcUrl: 'https://mainnet.base.org',
    }
};

export function getChainMeta(id: ChainIdsInt): ChainMeta {
    return Chains[id];
}

export function getAvailableChainsIds(): number[] {
    const isStageEnv = !isProd();
    const chains = [
        ChainIdsInt.ArbitrumOne,
        ChainIdsInt.Avalanche,
        ChainIdsInt.BinanceSmartChain,
        ChainIdsInt.EthereumMainnet,
        ChainIdsInt.OptimismMainnet,
        ChainIdsInt.PolygonMainnet,
    ];

    if (isStageEnv) {
        // stage chains go here
        chains.push(ChainIdsInt.Base);
    }

    return chains;
}

export function getAvailableChainIdsHex(): string[] {
    return getAvailableChainsIds().map(toHex);
}

export function getChains(): ChainMeta[] {
    return getAvailableChainsIds().map(getChainMeta);
}
