import { getSacAppBaseApiUrl } from 'helpers/api';
import { Logger } from 'helpers/logging';

export interface ApiReferral {
    address: string;
    date: string;
    value: number;
}

export interface FetchReferralsResponse {
    referrals: ApiReferral[];
    referrer: string | null;
    referrer_2: string | null;
    meta: {
        is_referral_active: boolean;
    };
}

export async function fetchReferrals(address: string, chain: string): Promise<FetchReferralsResponse> {
    const baseUrl = getSacAppBaseApiUrl();
    const qsp = new URLSearchParams({
        address,
        chain,
    });

    const url = `${baseUrl}/bankx/referral?${qsp}`;
    Logger.log('fetchReferrals: url', url);

    const response = await fetch(url);
    return response.json();
}
