import { Logger } from 'helpers/logging';
import { truncateDecimalValue } from 'helpers/number';
import { Storage } from 'helpers/storage';
import { getThemedClassName } from 'helpers/theme';
import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useRef,
    useState,
} from 'react';

interface AppSettings {
    isDarkTheme: boolean;
    isSidebarOpen: boolean;
    slippage: {
        isAuto: boolean;
        value: string;
    }
}

interface SettingsContext extends AppSettings {
    addSlippage(value: string, minSlippage?: string): string; // for applying slippage on the input
    subtractSlippage(value: string): string; // for applying slippage on the expected output
    divideBySlippage(value: string): string; // for calculating the max button amount
    getThemedClass(className: string, disableDarkTheme?: boolean): string;
    updateSettings: Dispatch<SetStateAction<AppSettings>>;
}

const Context = createContext<SettingsContext>({
    isDarkMode: null,
    isSidebarOpen: null,
} as unknown as SettingsContext);

export const MIN_SLIPPAGE_VALUE = 0.1;
export const MAX_SLIPPAGE_VALUE = 50;
const SETTINGS_KEY = '_2022capp_s';

const storedSettings = JSON.parse(Storage.get(SETTINGS_KEY) || '{}');

export function AppSettingsProvider({ children }: React.PropsWithChildren): React.ReactElement {
    const [settings, updateSettings] = useState<AppSettings>({
        isSidebarOpen: false,
        slippage: {
            isAuto: true,
            value: String(MIN_SLIPPAGE_VALUE)
        },
        ...storedSettings,

        // an override; https://ossllc.atlassian.net/browse/BAN-515
        isDarkTheme: true,
    });

    const slippageRef = useRef(settings.slippage);
    slippageRef.current = settings.slippage;

    // this function adds the slippage amount to the input value
    const divideBySlippage = useCallback((value: string): string => {
        const input = Number(value);

        if (!value || Number.isNaN(input)) {
            return '';
        }

        const adjustment = 1 + Number(slippageRef.current.value) / 100;
        const result = String(input / adjustment);

        Logger.log('divideBySlippage', input, adjustment, result);

        return result;
    }, []);

    // this function adds the slippage amount to the input value
    const addSlippage = useCallback((value: string, minSlippage = '0'): string => {
        const input = Number(value);

        if (!value || Number.isNaN(input)) {
            return '';
        }

        const slippage = Math.max(Number(minSlippage), Number(slippageRef.current.value));
        const adjustment = 1 + Number(slippage) / 100;
        const result = String(input * adjustment);

        Logger.log('addSlippage', input, adjustment, result);

        return truncateDecimalValue(result, 18);
    }, []);

    // this function subtracts the slippage amount to the output value
    const subtractSlippage = useCallback((value: string, minSlippage = '0'): string => {
        const input = Number(value);

        if (!value || Number.isNaN(input)) {
            return '';
        }

        const slippage = Math.max(Number(minSlippage), Number(slippageRef.current.value));
        const adjustment = 1 - Number(slippage) / 100;
        const result = String(input * adjustment);

        Logger.log('subtractSlippage', input, adjustment, result);

        return truncateDecimalValue(result, 18);
    }, [slippageRef]);

    const getThemedClass = useCallback((className: string, disableDarkTheme = false): string => {
        return getThemedClassName(className, settings.isDarkTheme && !disableDarkTheme);
    }, [settings.isDarkTheme]);

    const updateSettingsWrapper: Dispatch<SetStateAction<AppSettings>> = useCallback((update): void => {
        if (typeof update === 'function') {
            updateSettings((prev) => {
                const result = update(prev);
                Storage.set(SETTINGS_KEY, JSON.stringify(result));

                return result;
            });
        } else {
            updateSettings(update);
            Storage.set(SETTINGS_KEY, JSON.stringify(update));
        }

    }, [updateSettings]);

    const value = {
        ...settings,
        addSlippage,
        divideBySlippage,
        subtractSlippage,
        updateSettings: updateSettingsWrapper,
        getThemedClass,
    };

    return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useAppSettings(): SettingsContext {
    return useContext(Context);
}
