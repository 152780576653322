import { BigNumber, ethers } from 'ethers';
import { Logger } from 'helpers/logging';
import { CustomLoadingMessage } from 'hooks/useCustomLoadingMessages';

import {
    toBigIntString, toDecimalString
} from './number';

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export type TNFTInfo = {
    tiersCount: number;
    tiers: {
        tier: number;
        lowerBound: number;
        upperBound: number;
        price: number;
        totalSupply: number;
        isSoldOut: boolean;
    }[];
    totalSupply: number;
}

export type TNFT = {
    id: number,
    rewardsAvailable: number,
    rewardsClaimed: number,
}

export function getNFTLabel(nftId: number) {
    switch (true) {
        case 0 <= nftId && nftId < 1_250:
            return `NFT #${nftId} Currency Creator Silver`;
        case 1_250 <= nftId && nftId < 2_500:
            return `NFT #${nftId} Currency Creator Transformation`;
        case 2_500 <= nftId && nftId < 3_750:
            return `NFT #${nftId} Currency Creator XSD`;
        case 3_750 <= nftId:
        case nftId < 0:
        default:
            return nftId.toString();
    }
}

// TODO:refactor to use nftTiers
// https://github.com/Lance-Parker/CD/blob/13f5169fe5af708ba213700e0a8eb49280668fdf/contracts/BankXNFT.sol#L26
export function getTotalPurchasePrice(purchaseAmount: number, totalSupply: number, chain: string) {
    let totalPrice = 0;
    for (let i = totalSupply; i < totalSupply + purchaseAmount; i++) {
        switch (true) {
            case 0 <= i && i < 1_250:
                totalPrice += {
                    '0xfa': 450,
                    '0x89': 200,
                    '0xa86a': 10,
                }[chain] || 0.5;
                break;
            case 1_250 <= i && i < 2_500:
                totalPrice += {
                    '0xfa': 650,
                    '0x89': 300,
                    '0xa86a': 14,
                }[chain] || 0.75;
                break;
            case 2_500 <= i && i < 3_750:
                totalPrice += {
                    '0xfa': 850,
                    '0x89': 400,
                    '0xa86a': 18,
                }[chain] || 1;
                break;
            case 3_750 <= i && i <= 5_000:
            default:
                totalPrice += {
                    '0xfa': 1150,
                    '0x89': 500,
                    '0xa86a': 22,
                }[chain] || 1.25;
                break;
        }
    }

    return totalPrice;
}

export function getNFTTier(index: number, chain: string): {
    max: number;
    maxTier: number;
    upperBound?: number;
    name?: string;
    price?: number;
    tierAmount?: number;
    maxUserCanBuy?: number;
} {
    const baseInfo = {
        max: 5_000,
        maxTier: 1_250,
    };

    switch (true) {
        case index < 0:
            return baseInfo;
        case 0 <= index && index < 1_250:
            return {
                ...baseInfo,
                upperBound: 1_250,
                name: 'Silver',
                price: {
                    '0xfa': 450,
                    '0x89': 200,
                    '0xa86a': 10,
                }[chain] || 0.5,
                tierAmount: index,
                maxUserCanBuy: 1_250 - index,
            };
        case 1_250 <= index && index < 2_500:
            return {
                ...baseInfo,
                upperBound: 2_500,
                name: 'Transformation',
                price: {
                    '0xfa': 650,
                    '0x89': 300,
                    '0xa86a': 14,
                }[chain] || 0.75,
                tierAmount: index - 1_250,
                maxUserCanBuy: 2_500 - index,
            };
        case 2_500 <= index && index < 3_750:
            return {
                ...baseInfo,
                upperBound: 3_750,
                name: 'XSD',
                price: {
                    '0xfa': 850,
                    '0x89': 400,
                    '0xa86a': 18,
                }[chain] || 1,
                tierAmount: index - 2_500,
                maxUserCanBuy: 3_750 - index,
            };
        case 3_750 <= index && index <= 5_000:
        default:
            return {
                ...baseInfo,
                upperBound: 5_000,
                name: 'Electricity',

                price: {
                    '0xfa': 1150,
                    '0x89': 500,
                    '0xa86a': 22,
                }[chain] || 1.25,
                tierAmount: index - 3_750,
                maxUserCanBuy: 5_000 - index,
            };
    }
}

export async function getNFTInfo(
    BankXNFTContract: ethers.Contract,
): Promise<TNFTInfo> {
    const nftTiersRaw = await BankXNFTContract.nftTiers();
    const tiersCount = Number(toDecimalString(nftTiersRaw, 0));

    const tiers = [];

    for (let i = 1; i < tiersCount + 1; i++) {
        const lowerBoundRaw = await BankXNFTContract.nftTierLowerBound(i);
        const lowerBound = Number(toDecimalString(lowerBoundRaw, 0));

        const upperBoundRaw = await BankXNFTContract.nftTierUpperBound(i);
        const upperBound = Number(toDecimalString(upperBoundRaw, 0));

        const priceRaw = await BankXNFTContract.nftTierPrice(i);
        const price = Number(toDecimalString(priceRaw, 18));

        const totalSupplyRaw = await BankXNFTContract.totalSupply(i);
        const totalSupply = Number(toDecimalString(totalSupplyRaw, 0));

        tiers.push({
            tier: i,
            lowerBound,
            upperBound,
            price,
            totalSupply,
            isSoldOut: totalSupply >= upperBound,
        });
    }

    const lastNftId = await BankXNFTContract.lastNftId();
    const totalSupply = Number(toDecimalString(lastNftId, 0));

    Logger.log('getNFTInfo', {
        tiersCount,
        tiers,
        totalSupply,
    });

    return Promise.resolve({
        tiersCount,
        tiers,
        totalSupply,
    });
}

export async function mintNFT(
    amount: number,
    totalSupply: number,
    bankXNFTContract: ethers.Contract,
    bankXNFTCollectorContract: ethers.Contract,
    provider: ethers.providers.Web3Provider,
    walletAddress: string,
    goToNextLoadingMessage: (message?: string) => void,
    referrerAddress?: string | null,
    level2ReferrerAddress?: string | null,
    chain?: string
) {
    Logger.log('mintNFT:', {
        amount,
        BankXNFT: bankXNFTContract,
        BankXNFTCollector: bankXNFTCollectorContract,
        provider,
        walletAddress,
        referrerAddress,
        level2ReferrerAddress,
    });
    if (typeof amount !== 'number' || amount < 1) {
        return;
    }

    const totalPurchasePrice = getTotalPurchasePrice(amount, totalSupply, chain || '');

    const signer = provider.getSigner();
    const fallbackAddress = bankXNFTCollectorContract?.address || ZERO_ADDRESS;

    goToNextLoadingMessage(CustomLoadingMessage.MintingNFT);

    const txReceipt = await bankXNFTContract.connect(signer).mint(
        walletAddress,
        amount,
        referrerAddress || fallbackAddress,
        level2ReferrerAddress || fallbackAddress,
        [],
        { value: toBigIntString(totalPurchasePrice, 18) }
    );

    return await txReceipt.wait(1);
}

export async function getNFTsOwned(
    walletAddress: string,
    BankXNFTContract: ethers.Contract,
    NFTBonusContract: ethers.Contract,
    totalSupply: number,
    includeStakedInCD = false
) {
    const userArray = Array(totalSupply + 1).fill(walletAddress);
    const nftIndexesToCheck = Array.from({ length: totalSupply + 1 }, (item, index) => index);
    const balanceOfRaw = await BankXNFTContract.balanceOfBatch(userArray, nftIndexesToCheck);
    const nftsOwned = [];

    // get owned but not staked in CD
    for (let i = 0; i < balanceOfRaw.length; i++) {
        const balance = Number(toDecimalString(balanceOfRaw[i], 0));

        if (balance) {
            const rewardsAvailableRaw = await BankXNFTContract.getRewards(i);
            const rewardsAvailable = Number(toDecimalString(rewardsAvailableRaw, 18));

            const rewardsClaimedRaw = await BankXNFTContract.nftRewardsClaimed(i);
            const rewardsClaimed = Number(toDecimalString(rewardsClaimedRaw, 18));

            nftsOwned.push({
                id: i,
                rewardsAvailable,
                rewardsClaimed,
                isStakedInCD: false
            });
        }
    }

    if (includeStakedInCD) {
        // get NFTs staked in CD, but not currently in user's wallet
        const stakedNFTsRaw = await NFTBonusContract.getEntityStakedNftIds(walletAddress);

        for (let i = 0; i < stakedNFTsRaw.length; i++) {
            try {
                const id = Number(toDecimalString(stakedNFTsRaw[i], 0));
                const rewardsAvailableRaw = await BankXNFTContract.getRewards(id);
                const rewardsAvailable = Number(toDecimalString(rewardsAvailableRaw, 18));

                const rewardsClaimedRaw = await BankXNFTContract.nftRewardsClaimed(id);
                const rewardsClaimed = Number(toDecimalString(rewardsClaimedRaw, 18));

                nftsOwned.push({
                    id,
                    rewardsAvailable,
                    rewardsClaimed,
                    isStakedInCD: true
                });
            } catch (e) {
                Logger.log('error getting staked NFT info', e);
            }
        }
    }

    Logger.log('getNFTsOwned:', {
        nftsOwned,
        balanceOfRaw,
        nftIndexesToCheck,
        totalSupply
    });

    return Promise.resolve(nftsOwned);
}

// get NFTs owned by user but stuck in contract awaiting CD stakeStart
export async function getNFTsInLimbo(
    walletAddress: string,
    NFTBonusContract: ethers.Contract,
) {
    const _results = await Promise.allSettled([
        NFTBonusContract.entityStakedNftIdsTemp(walletAddress, 0),
        NFTBonusContract.entityStakedNftIdsTemp(walletAddress, 1),
        NFTBonusContract.entityStakedNftIdsTemp(walletAddress, 2),
        NFTBonusContract.entityStakedNftIdsTemp(walletAddress, 3),
        NFTBonusContract.entityStakedNftIdsTemp(walletAddress, 4),
    ]);

    const nftsInLimbo = _results
        .filter((promise) => promise.status === 'fulfilled');

    Logger.log('getNFTsInLimbo', {
        nftsInLimbo,
    });

    return nftsInLimbo.length;
}

export async function claimNFTRewards(
    nftID: number,
    BankXNFTContract: ethers.Contract,
    provider: ethers.providers.Web3Provider,
    goToNextLoadingMessage: (message?: string) => void,
) {
    const signer = provider.getSigner();

    goToNextLoadingMessage(CustomLoadingMessage.Claiming);

    const rewardsAvailableRaw = await BankXNFTContract.getRewards(nftID);

    const txReceipt = await BankXNFTContract
        .connect(signer)
        .claimRewards(
            nftID,
            rewardsAvailableRaw
        );

    return await txReceipt.wait(1);
}

export async function unstakeNFTsInLimbo(
    walletAddress: string,
    NFTBonusContract: ethers.Contract,
    provider: ethers.providers.Web3Provider
) {
    const signer = provider.getSigner();

    const _results = await Promise.allSettled<BigNumber>([
        NFTBonusContract.entityStakedNftIdsTemp(walletAddress, 0),
        NFTBonusContract.entityStakedNftIdsTemp(walletAddress, 1),
        NFTBonusContract.entityStakedNftIdsTemp(walletAddress, 2),
        NFTBonusContract.entityStakedNftIdsTemp(walletAddress, 3),
        NFTBonusContract.entityStakedNftIdsTemp(walletAddress, 4),
    ]);

    const nftsInLimbo = _results
        .filter((promise) => promise.status === 'fulfilled')
        .map((promise) => (promise as PromiseFulfilledResult<BigNumber>).value.toString());

    const unstakePromises = Array.from(nftsInLimbo, async (nftId, index) => {
        const txReceipt = await NFTBonusContract
            .connect(signer)
            .unstakeNFTInLimbo(nftId);
        return txReceipt.wait(1);
    });

    try {
        await Promise.all(unstakePromises);
        return Promise.resolve();
    } catch (error) {
        throw error;
    }
}
