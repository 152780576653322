import {
    ApiReferral, fetchReferrals, FetchReferralsResponse
} from 'api/fetchReferrals';
import { saveReferrer } from 'api/saveReferrer';
import { Logger } from 'helpers/logging';
import { Storage } from 'helpers/storage';
import { useWallet } from 'hooks/useWallet';
import React, {
    createContext, useEffect, useState
} from 'react';
import { useSearchParams } from 'react-router-dom';

const STORAGE_KEY = 'bx_ref_kj1298384';
const REWARD_PERCENTAGE = 0.1;

interface Referral extends ApiReferral {
    reward: number;
}

interface ReferralsResponse extends FetchReferralsResponse {
    referrals: Referral[];
    referrer: string | null;
    meta: {
        is_referral_active: boolean;
    }
}

export interface ReferralsContext extends ReferralsResponse {
    xsdEarned: number;
}

const defaultContext = {
    referrer: null,
    referrer_2: null,
    referrals: [],
    meta: {
        is_referral_active: false,
    },
    xsdEarned: 0,
};

export const Context = createContext<ReferralsContext>({ ...defaultContext });

function isValidAddress(address: string | null): boolean {
    return address?.length === 42;
}

async function checkReferral(userAddress: string | null, referrerAddress: string | null): Promise<string> {
    if (isValidAddress(referrerAddress)) {
        Storage.set(STORAGE_KEY, referrerAddress as string);
    }

    const referrer = Storage.get(STORAGE_KEY);
    const isValidReferrer = isValidAddress(referrer);
    const isValidUser = isValidAddress(userAddress);

    if (!isValidReferrer) {
        Storage.delete(STORAGE_KEY);
        return '';
    }

    if (!isValidUser) {
        return '';
    }

    const isSuccess = await saveReferrer(userAddress as string, referrer as string);

    if (isSuccess) {
        Storage.delete(STORAGE_KEY);
    }

    return userAddress || '';
}

export function ReferralsProvider(props: React.PropsWithChildren): React.ReactElement {
    const [data, setData] = useState<ReferralsContext>(defaultContext);
    const { address: userAddress, chain } = useWallet();
    const [params] = useSearchParams();
    const referrerAddress = params.get('ref');

    useEffect((): void => {
        checkReferral(userAddress, referrerAddress).then(savedAddress => {
            if (savedAddress) {
                setData(prev => {
                    return {
                        ...prev,
                        referrer: savedAddress,
                    };
                });
            }
        });
    }, [referrerAddress, userAddress]);

    useEffect((): void => {
        if (!userAddress) {
            Logger.log('ReferralsProvider: no user address found');
            setData({ ...defaultContext });
            return;
        }

        fetchReferrals(userAddress, chain as string).then((data: FetchReferralsResponse): void => {
            let xsdEarned = 0;
            const referralsWithRewards = data.referrals.map((referral): Referral => {
                const reward = referral.value * REWARD_PERCENTAGE;
                xsdEarned += reward;

                return {
                    ...referral,
                    reward,
                };
            });
            Logger.log('ReferralsProvider: referralsWithRewards', referralsWithRewards);

            setData({
                referrer: data.referrer,
                referrer_2: data.referrer_2,
                referrals: referralsWithRewards,
                xsdEarned,
                meta: data.meta,
            });
        }).catch(error => {
            Logger.log(`ReferralsProvider: Error fetching referrals: ${error}`);
        });
    }, [chain, userAddress]);

    return (
        <Context.Provider value={data}>
            {props.children}
        </Context.Provider>
    );
}
