import {
    defaultState,
    NftLeaderboard,
    useGetNftLeaderboard,
} from 'hooks/useGetNftLeaderboard';
import { useWallet } from 'hooks/useWallet';
import React, {
    createContext, useContext, useState
} from 'react';

// for convenience
export type { LeaderboardEntry } from 'hooks/useGetNftLeaderboard';

interface NftLeaderboardContextValue extends NftLeaderboard {
    selectedChain: string;
    setSelectedChain: (chain: string) => void;
}

const Context = createContext<NftLeaderboardContextValue>({
    ...defaultState,
    selectedChain: 'ethereum',
    setSelectedChain: () => undefined,
});

export function NftLeaderboardProvider({ children }: React.PropsWithChildren): React.ReactElement {
    const { chain: walletChain } = useWallet();
    const [selectedChain, setSelectedChain] = useState<string>(walletChain ?? 'ethereum');
    const data = useGetNftLeaderboard(selectedChain);

    const value = {
        ...data,
        selectedChain,
        setSelectedChain,
    };

    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    );
}

export function useNftLeaderboard(): NftLeaderboardContextValue {
    return useContext(Context);
}
