import { AddressMap } from './types';

export const PolygonAddresses: AddressMap = {
    BankXToken:           '0xeeA52F6587F788cc12d0b5a28c48e61866c076F0',
    XSDToken:             '0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e',
    BankXPool:            '0x59cA927Ae4c900dC8091515191E39B010bec1118',
    XSDPool:              '0x58421507d10A4c57a761E8AAd5382D5564A682F5',
    CollateralPool:       '0xF99b76386e5a30534406f4d34E7610E1F6f16e6f',
    PIDController:        '0xCedfEb69C150AD62A164C5B0bc55718913F9b0D8',
    RewardManager:        '0x214Efd40ca2990552ceBbeBC9b3daF49F1f07674',
    RouterAddress:        '0xF37c90E8453046684e91588a5779D2924F876231',
    Arbitrage:            '0xf049659C5c16f72Cec19975851233762B37b5b2A',
    CertificateOfDeposit: '0xc79461C653bCd0137D062ec87B9b821f7c9bCc26',
    BankXNFT:             '0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C',
    NFTBonus:             '0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC',
    NFTCollector:         '0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5',
};
// 01/22/2025 1355PST
// Collateral Pool:         0xF99b76386e5a30534406f4d34E7610E1F6f16e6f

// 01/09/2025 1317PST
// BankXToken:              0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// XSDToken:                0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// BankXPool:               0x59cA927Ae4c900dC8091515191E39B010bec1118
// XSDPool:                 0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Collateral Pool:         0xb09CE73839b4F811B8400dfb289C67Bf8aC46283
// PID:                     0xCedfEb69C150AD62A164C5B0bc55718913F9b0D8
// RewardManager:           0x214Efd40ca2990552ceBbeBC9b3daF49F1f07674
// RouterAddress:           0xF37c90E8453046684e91588a5779D2924F876231
// Arbitrage:               0xf049659C5c16f72Cec19975851233762B37b5b2A
// CertificateOfDeposit:    0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT:                0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:                0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
// NFTCollector:            0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5

// 11/30/2024 1231DT
// BankXToken:              0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// XSDToken:                0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// BankXPool:               0x59cA927Ae4c900dC8091515191E39B010bec1118
// XSDPool:                 0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Collateral Pool:         0x1E9cDeB764661c5dF4108A8cc30D4B063aFaaf38
// PID:                     0xCedfEb69C150AD62A164C5B0bc55718913F9b0D8
// RewardManager:           0x214Efd40ca2990552ceBbeBC9b3daF49F1f07674
// RouterAddress:           0xF37c90E8453046684e91588a5779D2924F876231
// Arbitrage:               0xf049659C5c16f72Cec19975851233762B37b5b2A
// CertificateOfDeposit:    0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT:                0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:                0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
// NFTCollector:            0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5

// 11/25/2024 1205PST
// BankXToken:              0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// XSDToken:                0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// BankXPool:               0x59cA927Ae4c900dC8091515191E39B010bec1118
// XSDPool:                 0x58421507d10A4c57a761E8AAd5382D5564A682F5
// CollateralPool:          0x1E9cDeB764661c5dF4108A8cc30D4B063aFaaf38
// PIDController:           0x6bC87884694992bE17AD040dd942A96780182fC0
// RewardManager:           0x214Efd40ca2990552ceBbeBC9b3daF49F1f07674
// RouterAddress:           0xF37c90E8453046684e91588a5779D2924F876231
// Arbitrage:               0xf049659C5c16f72Cec19975851233762B37b5b2A
// CertificateOfDeposit:    0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT:                0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:                0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 11/07/2024 2207PST
// BankXToken:              0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// XSDToken:                0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// BankXPool:               0x59cA927Ae4c900dC8091515191E39B010bec1118
// XSDPool:                 0x58421507d10A4c57a761E8AAd5382D5564A682F5
// CollateralPool:          0x021aE16F7AA807b3cC96625CC30Ae40FcA374bc0
// PIDController:           0xF6Db5A9078Fd50836202c0DC7b0980341b52F124
// RewardManager:           0x214Efd40ca2990552ceBbeBC9b3daF49F1f07674
// RouterAddress:           0xF37c90E8453046684e91588a5779D2924F876231
// Arbitrage:               0xf049659C5c16f72Cec19975851233762B37b5b2A
// CertificateOfDeposit:    0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT:                0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:                0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 11/01/2024 1452PDT
// BankX Token address:     0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// XSD Token address:       0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// BankX Pool address:      0x59cA927Ae4c900dC8091515191E39B010bec1118
// XSDPool address:         0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Collateral Pool address: 0x4BD11692B9F282b9EeeE26b607e5666345284905
// PID controller address:  0xF6Db5A9078Fd50836202c0DC7b0980341b52F124
// Reward Manager:          0x214Efd40ca2990552ceBbeBC9b3daF49F1f07674
// Router address:          0xF37c90E8453046684e91588a5779D2924F876231
// Arbitrage:               0xf049659C5c16f72Cec19975851233762B37b5b2A
// CD:                      0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                     0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:                0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 10/04/2024 1524PDT
// BankX Token address:     0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// XSD Token address:       0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// BankX Pool address:      0x59cA927Ae4c900dC8091515191E39B010bec1118
// XSDPool address:         0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Collateral Pool address: 0x4BD11692B9F282b9EeeE26b607e5666345284905
// PID controller address:  0x016357A87EEf79BdC03B613FB4415c1486905977
// Reward Manager:          0x214Efd40ca2990552ceBbeBC9b3daF49F1f07674
// Router address:          0xF37c90E8453046684e91588a5779D2924F876231
// Arbitrage:               0xf049659C5c16f72Cec19975851233762B37b5b2A
// CD:                      0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                     0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:                0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 9/27/2024 1312PDT
// BankX Token address:     0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// XSD Token address:       0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// BankX Pool address:      0x59cA927Ae4c900dC8091515191E39B010bec1118
// XSDPool address:         0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Collateral Pool address: 0x4BD11692B9F282b9EeeE26b607e5666345284905
// PID controller address:  0x7b51Dd3B546A9e4a2a894620eCa083af252C52Db
// Reward Manager:          0x214Efd40ca2990552ceBbeBC9b3daF49F1f07674
// Router address:          0xF37c90E8453046684e91588a5779D2924F876231
// Arbitrage:               0xf049659C5c16f72Cec19975851233762B37b5b2A
// CD:                      0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                     0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:                0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 8/26/2024 1944 PDT
// BankX Token address:     0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// XSD Token address:       0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// BankX Pool address:      0x59cA927Ae4c900dC8091515191E39B010bec1118
// XSDPool address:         0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Collateral Pool address: 0x86f427a5612525dc62Bd5939C8577c54A74D2746
// PID controller address:  0x684D8272639A18B67DAf759e4C6042332c6CB91a
// Reward Manager:          0x214Efd40ca2990552ceBbeBC9b3daF49F1f07674
// Router address:          0xF37c90E8453046684e91588a5779D2924F876231
// Arbitrage:               0xf049659C5c16f72Cec19975851233762B37b5b2A
// CD:                      0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                     0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:                0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 02/20/2024 1209PST
// BankX Token address:     0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// XSD Token address:       0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// BankX Pool address:      0x59cA927Ae4c900dC8091515191E39B010bec1118
// XSDPool address:         0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Collateral Pool address: 0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// PID controller address:  0xd138CAe6088A41CEF5acd8b332ACea349Cd91d82
// Reward Manager:          0x45B07536b320297eBf525a87373A13dAf10b17f3
// Router address:          0xc13f4DfAA06481b323629c73D715be317DAc6677
// Arbitrage:               0xf049659C5c16f72Cec19975851233762B37b5b2A
// CD address               0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address         0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus address         0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
